// import PProviderService from '@/services/api/Provider/PProvider'
import RulesService from "@/services/rules";
import GUserAccountService from "@/services/api/General/GUserAccount";

export default {
  name: "v-view-account",
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      busy: false,
      gUserAccount: {},
    };
  },
  methods: {
    /**
     *
     */
    // getUserAccount() {
    //   this.gUserAccount = this.$store.state.UserModule.data;
    // },
    /**
     *
     */
    handleRepeatPasswordRule() {
      return (
        this.gUserAccount.newPassword === this.gUserAccount.repeat_password ||
        "Las contraseñas deben ser las mismas."
      );
    },
    handleFormReset() {
      this.gUserAccount = GUserAccountService.shape({
        currentPassword: "",
        newPassword: "",
        repeat_password: "",
      });
    },

    /**
     *
     */
    async handleFormSubmit() {
      this.busy = true;
      let draft = this.$store.state.UserModule.data;
      this.gUserAccount.rut = draft.rut;
      // this.gUserAccount.currentPassword = draft.password;
      await GUserAccountService.changePassword(
        this.gUserAccount,
        this.gUserAccount.newPassword
      )
        .then(this.handleChangePasswordSuccess)
        .catch(this.handleChangePasswordError);
      this.busy = false;
    },

    /**
     *
     */
    handleChangePasswordError(payload) {
      GUserAccountService.translate(payload).then(this.$toast.error);
    },

    /**
     *
     */
    handleChangePasswordSuccess(payload) {
      GUserAccountService.translate({ response: payload }).then(
        this.$toast.success
      );
    },
  },
  // mounted() {
  //   this.getUserAccount();
  // },
  created() {
    this.handleFormReset();
  },
  props: {
    /**
     *
     */
    passwordRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.min(6),
        RulesService.max(20),
      ],
    },
    /**
     *
     */
    repeatPasswordRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.min(6),
        RulesService.max(20),
      ],
    },
  },
};
